import React, { useState, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

export default function Head(props) {
  let { tag } = useParams();
  const [tagName, setTagName] = useState(tag);
  const [openFilter, setOpenFilter] = useState("");

  if (window.innerWidth > 768) {
    return (
      <div className="headColumns">
        <div className="headColumn menu">
          <div className="logo"><Link to="/">Art–Space–Hopping  2</Link></div>
          <div className="links">
            <p><Link to="/">Участники</Link></p>
            <p><Link to="/program">Программа</Link></p>
            <p><Link to="/about">О проекте</Link></p>
            <p><a href="https://www.google.com/maps/d/edit?mid=1S17N1Y36YLl-jpeGvXV4PWH1hOoejgri&usp=sharing" target='_blank'>Google карта</a></p>
          </div>
        </div>

        <div className="headColumn filters">
          {props.filters ? (
            <div className="headColumn filters">
              <div className="filter">
                <p onClick={() => openFilter == "Направление" ? setOpenFilter("") : setOpenFilter("Направление")}>{openFilter == 'Направление' ? '–> ' : '+ '}Направление</p>
                {openFilter == 'Направление' ? props.artTypeFilters.map((filter, i) => {
                  return(
                    <p
                      onClick={() => setTagName(filter)}
                      className={tagName && tagName.split('(')[0].slice(0, -1) == filter.split('&nbsp;(')[0] || tagName && tagName.split('&nbsp;(')[0] == filter.split('&nbsp;(')[0] ? 'selected' : ''}
                    >
                      <Link to={`/tags/${filter.replace(/(&nbsp;)/g, '\u00A0').replace('/', '')}`}>{filter.split('&nbsp;(')[0] != '' ? filter.replace(/(&nbsp;)/g, '\u00A0').toLowerCase() : ''}</Link>
                    </p>
                  )
                }) : ""}
              </div>

              <div className="filter">
                <p onClick={() => openFilter == "Формат" ? setOpenFilter("") : setOpenFilter("Формат")}>{openFilter == 'Формат' ? '–> ' : '+ '}Формат</p>
                {openFilter == 'Формат' ? props.formatFilters.map((filter, i) => {
                  return(
                    <p
                      onClick={() => setTagName(filter)}
                      className={tagName && tagName.split('(')[0].slice(0, -1) == filter.split('&nbsp;(')[0] || tagName && tagName.split('&nbsp;(')[0] == filter.split('&nbsp;(')[0] ? 'selected' : ''}
                    >
                      <Link to={`/tags/${filter.replace(/(&nbsp;)/g, '\u00A0').replace('/', '')}`}>{filter.split('&nbsp;(')[0] != '' ? filter.replace(/(&nbsp;)/g, '\u00A0').toLowerCase() : ''}</Link>
                    </p>
                  )
                }) : ""}
              </div>

              <div className="filter">
                <p onClick={() => openFilter == "Локация" ? setOpenFilter("") : setOpenFilter("Локация")}>{openFilter == 'Локация' ? '–>' : '+ '}Локация</p>
                {openFilter == 'Локация' ? props.locationFilters.map((filter, i) => {
                  return(
                    <p
                      onClick={() => setTagName(filter)}
                      className={tagName && tagName.split('(')[0].slice(0, -1) == filter.split('&nbsp;(')[0] || tagName && tagName.split('&nbsp;(')[0] == filter.split('&nbsp;(')[0] ? 'selected' : ''}
                    >
                      <Link to={`/tags/${filter.replace(/(&nbsp;)/g, '\u00A0').replace('/', '')}`}>{filter.split('&nbsp;(')[0] != '' ? filter.replace(/(&nbsp;)/g, '\u00A0').toLowerCase() : ''}</Link>
                    </p>
                  )
                }) : ""}
              </div>

              <div className="filter">
                <p onClick={() => openFilter == "Техника" ? setOpenFilter("") : setOpenFilter("Техника")}>{openFilter == 'Техника' ? '–> ' : '+ '}Техника</p>
                {openFilter == 'Техника' ? props.artTechniqueFilters.map((filter, i) => {
                  return(
                    <p
                      onClick={() => setTagName(filter)}
                      className={tagName && tagName.split('(')[0].slice(0, -1) == filter.split('&nbsp;(')[0] || tagName && tagName.split('&nbsp;(')[0] == filter.split('&nbsp;(')[0] ? 'selected' : ''}
                    >
                      <Link to={`/tags/${filter.replace(/(&nbsp;)/g, '\u00A0').replace('/', '')}`}>{filter.split('&nbsp;(')[0] != '' ? filter.replace(/(&nbsp;)/g, '\u00A0').toLowerCase() : ''}</Link>
                    </p>
                  )
                }) : ""}
              </div>
            </div>
          ) : ''}
        </div>

        <div className="headColumn">
        </div>
        <div className="headColumn links">
          <p><a href="https://www.instagram.com/a_s_h_mscw/" target="_blank">Instagram</a></p>
          <p><a href="https://t.me/artspacehopping" target="_blank">Telegram</a></p>
          <p><a href="https://art-space-hopping.timepad.ru/event/1606257/" target="_blank">Билет</a></p>
        </div>
      </div>
    );
  } else {
    return (
      <div className="headColumns">
        <div className="menuWrapper">
          <div className="headColumn menu">
            <div className="logo"><Link to="/">Art–Space–<br/>Hopping  2</Link></div>
            <div className="links">
              <p><Link to="/">Участники</Link></p>
              <p><Link to="/program">Программа</Link></p>
              <p><Link to="/about">О проекте</Link></p>
              <p><a href="https://www.google.com/maps/d/edit?mid=1S17N1Y36YLl-jpeGvXV4PWH1hOoejgri&usp=sharing" target='_blank'>Google карта</a></p>
            </div>
          </div>

          <div className="headColumn links">
            <p><a href="https://www.instagram.com/a_s_h_mscw/" target="_blank">Instagram</a></p>
            <p><a href="https://t.me/artspacehopping" target="_blank">Telegram</a></p>
            <p><a href="https://art-space-hopping.timepad.ru/event/1606257/" target="_blank">Билет</a></p>
          </div>
        </div>

        <div className="headColumn filters">
          {props.filters ? (
            <div className="headColumn filters">
              <div className="filter">
                <p onClick={() => openFilter == "Направление" ? setOpenFilter("") : setOpenFilter("Направление")}>{openFilter == 'Направление' ? '–> ' : '+ '}Направление</p>
                {openFilter == 'Направление' ? props.artTypeFilters.map((filter, i) => {
                  return(
                    <p
                      onClick={() => setTagName(filter)}
                      className={tagName && tagName.split('(')[0].slice(0, -1) == filter.split('&nbsp;(')[0] || tagName && tagName.split('&nbsp;(')[0] == filter.split('&nbsp;(')[0] ? 'selected' : ''}
                    >
                      <Link to={`/tags/${filter.replace(/(&nbsp;)/g, '\u00A0').replace('/', '')}`}>{filter.split('&nbsp;(')[0] != '' ? filter.replace(/(&nbsp;)/g, '\u00A0').toLowerCase() : ''}</Link>
                    </p>
                  )
                }) : ""}
              </div>

              <div className="filter">
                <p onClick={() => openFilter == "Формат" ? setOpenFilter("") : setOpenFilter("Формат")}>{openFilter == 'Формат' ? '–> ' : '+ '}Формат</p>
                {openFilter == 'Формат' ? props.formatFilters.map((filter, i) => {
                  return(
                    <p
                      onClick={() => setTagName(filter)}
                      className={tagName && tagName.split('(')[0].slice(0, -1) == filter.split('&nbsp;(')[0] || tagName && tagName.split('&nbsp;(')[0] == filter.split('&nbsp;(')[0] ? 'selected' : ''}
                    >
                      <Link to={`/tags/${filter.replace(/(&nbsp;)/g, '\u00A0').replace('/', '')}`}>{filter.split('&nbsp;(')[0] != '' ? filter.replace(/(&nbsp;)/g, '\u00A0').toLowerCase() : ''}</Link>
                    </p>
                  )
                }) : ""}
              </div>

              <div className="filter">
                <p onClick={() => openFilter == "Локация" ? setOpenFilter("") : setOpenFilter("Локация")}>{openFilter == 'Локация ' ? '–>' : '+ '}Локация</p>
                {openFilter == 'Локация' ? props.locationFilters.map((filter, i) => {
                  return(
                    <p
                      onClick={() => setTagName(filter)}
                      className={tagName && tagName.split('(')[0].slice(0, -1) == filter.split('&nbsp;(')[0] || tagName && tagName.split('&nbsp;(')[0] == filter.split('&nbsp;(')[0] ? 'selected' : ''}
                    >
                      <Link to={`/tags/${filter.replace(/(&nbsp;)/g, '\u00A0').replace('/', '')}`}>{filter.split('&nbsp;(')[0] != '' ? filter.replace(/(&nbsp;)/g, '\u00A0').toLowerCase() : ''}</Link>
                    </p>
                  )
                }) : ""}
              </div>

              <div className="filter">
                <p onClick={() => openFilter == "Техника" ? setOpenFilter("") : setOpenFilter("Техника")}>{openFilter == 'Техника' ? '–> ' : '+ '}Техника</p>
                {openFilter == 'Техника' ? props.artTechniqueFilters.map((filter, i) => {
                  return(
                    <p
                      onClick={() => setTagName(filter)}
                      className={tagName && tagName.split('(')[0].slice(0, -1) == filter.split('&nbsp;(')[0] || tagName && tagName.split('&nbsp;(')[0] == filter.split('&nbsp;(')[0] ? 'selected' : ''}
                    >
                      <Link to={`/tags/${filter.replace(/(&nbsp;)/g, '\u00A0').replace('/', '')}`}>{filter.split('&nbsp;(')[0] != '' ? filter.replace(/(&nbsp;)/g, '\u00A0').toLowerCase() : ''}</Link>
                    </p>
                  )
                }) : ""}
              </div>
            </div>
          ) : ''}
        </div>

        <div className="headColumn">
        </div>
      </div>
    );
  }
}
