import React, { useState, useEffect, useRef } from "react";
import Tabletop from 'tabletop';
import Papa from "papaparse";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import graphicsImage from '../images/about-graphic.svg';
import graphicsImage2 from '../images/about-graphic-2.svg';
import mosmuseumRed from '../images/mosmuseum-red.svg';
import icaRed from '../images/ica-red.svg';
import shrRed from '../images/shr-red.svg';
import bhsadRed from '../images/bhsad-red.svg';
import moskinoRed from '../images/moskino-red.svg';
import composersRed from '../images/composers-red.svg';
import hseRed from '../images/hse-red.svg';
import souzRed from '../images/souz-red.svg';
import euroRed from '../images/euro-red.svg';
import akiRed from '../images/aki-red.svg';
import seasonsRed from '../images/seasons-red.svg';


export default function About() {
  const [data, setData] = useState([]);
  const graphicsRef = useRef(null);
  const graphics2Ref = useRef(null);
  const mainContentRef = useRef(null);

  useEffect(() => {
    Papa.parse('https://docs.google.com/spreadsheets/d/e/2PACX-1vR5m_laF6H7DpqMsy94kDUoRHQ1b7fbtpgsVh5O4ZuDMZVrnFufkwIEUg92CfqxDXzH8Ry_Ix68jbJg/pub?output=csv', {
      download: true,
      header: true,
      complete: function(results) {
        var googleData = results.data;
        setData(googleData);
      }
    })

    // timerScroll();
    // moveGraphics();

    // setTimeout(function () {
    //   graphicsRef.current.style.display = 'block';
    //   graphics2Ref.current.style.display = 'block';
    // }, 500);

    setTimeout(function () {
      mainContentRef.current.style.display = 'block';
    }, 10);

    // return () => {
    //   window.removeEventListener('scroll', setGraphicsDisplay)
    //   window.removeEventListener('scroll', moveGraphicsFunc)
    // }
  }, [])

  function timerScroll() {
    let lastScrollTime = 0;

    setInterval(function () {
      lastScrollTime += 1;

      if (lastScrollTime > 7) {
        graphicsRef.current.style.display = 'block';
        graphics2Ref.current.style.display = 'block';
      }
    }, 1000);

    window.addEventListener('scroll', setGraphicsDisplay(lastScrollTime))
  }

  function setGraphicsDisplay() {
    let lastScrollTime = 0;
    graphicsRef.current.style.display = 'none';
    graphics2Ref.current.style.display = 'none';
  }

  function moveGraphics() {
    window.addEventListener('scroll', moveGraphicsFunc)
  }

  function moveGraphicsFunc() {
    graphicsRef.current.style.marginTop = `${-window.scrollY * 3}px`;
    graphics2Ref.current.style.marginTop = `${-window.scrollY * 2.2}px`;
  }

  return (
    <div className="aboutPage">
      <div className="aboutPage-mainContent" ref={mainContentRef}>
        <p className="aboutPage-mainText">
          А&nbsp;что если место искусства не&nbsp;только в&nbsp;музеях и&nbsp;галереях? А&nbsp;что если вас будут ждать во&nbsp;всех художественных мастерских города? А&nbsp;что если композитор расскажет, как устроена музыка? А&nbsp;что если вы&nbsp;случайно встретитесь со&nbsp;знакомыми и&nbsp;продолжите свой путь вместе? А&nbsp;что если вы&nbsp;сократите дистанцию? А&nbsp;что если город станет более дружелюбным? А&nbsp;что если вы&nbsp;найдете своего художника? А&nbsp;что если искусство это не&nbsp;только картина? А&nbsp;что если картина это не&nbsp;искусство? А&nbsp;что если город создан для танца? А&nbsp;что если книгу в&nbsp;автобусе можно читать всем вместе и&nbsp;по&nbsp;кругу? А&nbsp;что если вы&nbsp;совсем не&nbsp;знакомы с&nbsp;городом?
          <br/><br/>
          Art-Space-Hopping пересобирает городское пространство, предлагая всем участникам&nbsp;&mdash; художникам, горожанам, тем, кто строит малый бизнес, формы взаимодействия посредством создания общего пространства, среды, где может проявиться все многообразие социальных связей. В&nbsp;изменение городского пространства вовлечены самые разные силы&nbsp;&mdash; администрация, застройщики, планировщики, корпорации. Проект конструирует ситуации, внутри которых каждый может ощутить, что способен производить пространство свободы через повседневные практики.
          <br/><br/>
          Музеи, галереи являются традиционными институциями для представления искусства, куда произведения попадают благодаря выбору куратора. Зрители встречают законченные работы, но&nbsp;им&nbsp;редко удается узнать контексты их&nbsp;создания. В&nbsp;фокусе проекта оказываются мастерские, где эти контексты становятся очевидными, здесь идеи проверяются, меняются, исчезают или продолжают развитие. Тут зарождается художественное сообщество.
          <br/><br/>
          Существует множество гибридных форматов&nbsp;&mdash; театр, изобразительное, перформативные практики, прогулки, показы и&nbsp;т.п., пространство искусства расширяется и&nbsp;поэтому меняются границы второго Art-Space-Hopping. В&nbsp;апреле в&nbsp;нем участвуют не&nbsp;только представители художественного сообщества, но&nbsp;и&nbsp;все, кто проявляет себя творчески в&nbsp;музыке, танцах, текстах и&nbsp;пограничных с&nbsp;искусством форматах.
          <br/><br/>
          Проект проходит при поддержке Музея Москвы, который также исследует возможности альтернативного городского пространства, раскрывает его по-новому, создавая маршруты и&nbsp;экскурсии по&nbsp;мастерским художников.
          <br/><br/>
          Art-Space-Hopping&nbsp;&mdash; это независимая инициатива двух художниц&nbsp;&mdash; Евгении&nbsp;Боневерт и&nbsp;Надежды Бушенёвой, проект вырос из&nbsp;образовательных и&nbsp;художественных практик, а&nbsp;также из&nbsp;интереса к&nbsp;развитию городской среды.
          <br/><br/>
          Нам по-прежнему важно, чтобы искусство было доступным, как альтернативная форма опыта и&nbsp;знаний, поэтому вход на&nbsp;площадки открыт для всех. Но&nbsp;для того чтобы проект развивался нам необходима ваша поддержка. Покупая донейшн-билет на&nbsp;Timepad за&nbsp;200 рублей вы&nbsp;помогаете развитию проекта.
        </p>

        <p className="aboutPage-buy-button-p"><a className="aboutPage-buy-button" target="_blank" href="https://art-space-hopping.timepad.ru/event/1606257/">билеты</a></p>

        <div className="aboutPage-team-block">
          <p>Команда</p>
          <div className="aboutPage-team-members">
            <p>
              организаторы<br/>
              Евгения Боневерт<br/>
              Надежда Бушенёва<br/>
              <br/>
              координаторы проекта<br/>
              Катерина Тюрина<br/>
              Камилла Алахвердиева<br/>
              Екатерина Пименова<br/>
              Вита Осипова
            </p>
            <p>
              дизайн<br/>
              Дмитрий Гусев<br/>
              Миша Филатов<br/>
              <br/>
              разработка<br/>
              Данила Тимофеев
            </p>
            <p>Отдельное спасибо за&nbsp;помощь в&nbsp;фото и&nbsp;видео съемке студентам первого курса отделения „Кино и видео арт“ НИУ ВШЭ: Даше Гнатченко, Кате Шупик, Лине Хань, Алене Саакян, Соне Козловой, Марии Скворцовой</p>
          </div>
        </div>

        <img className="aboutPage-mosmuseum-logo" src={mosmuseumRed}/>
        <p className="aboutPage-mosmuseum-text">Музей Москвы — партнёр проекта Art-Space-Hopping. Музей Москвы основан в 1896 году. Музей располагается в Провиантских складах, памятнике архитектуры федерального значения. Фондовая коллекция насчитывает около 800 тысяч единиц хранения, в том числе богатое собрание археологических артефактов. Музей Москвы также включает несколько филиалов: Музей археологии Москвы, Музей истории Лефортово и Центр Гиляровского.</p>

        <div className="aboutPage-partners-block">
          <p>Информационные партнёры</p>
          <div className="aboutPage-partners-logos">
            <a target="_blank" href="https://icamoscow.ru"><img className="aboutPage-partner-logo icaRed" src={icaRed}/></a>
            <a target="_blank" href="https://mdfschool.ru/events/"><img className="aboutPage-partner-logo shrRed" src={shrRed}/></a>
            <a target="_blank" href=" https://mos-kino.ru"><img className="aboutPage-partner-logo moskinoRed" src={moskinoRed}/></a>
            <a target="_blank" href="https://britishdesign.ru"><img className="aboutPage-partner-logo bhsadRed" src={bhsadRed}/></a>
            <a target="_blank" href=""><img className="aboutPage-partner-logo hseRed" src={hseRed}/></a>
            <a target="_blank" href="https://www.unioncomposers.ru"><img className="aboutPage-partner-logo composersRed" src={composersRed}/></a>
            <a target="_blank" href="https://europapier.ru"><img className="aboutPage-partner-logo euroRed" src={euroRed}/></a>
            <a target="_blank" href="https://www.instagram.com/soyuzpechat.moscow/"><img className="aboutPage-partner-logo souzRed" src={souzRed}/></a>
            <a target="_blank" href="https://moscow-creative.ru/about/"><img className="aboutPage-partner-logo akiRed" src={akiRed}/></a>
            <a target="_blank" href="http://seasons-project.ru/life"><img className="aboutPage-partner-logo akiRed" src={seasonsRed}/></a>
          </div>
        </div>
      </div>
    </div>
  )
}
