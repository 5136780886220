import React, { useState, useEffect, useRef } from "react";
import Tabletop from 'tabletop';
import Papa from "papaparse";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

export default function Program(props) {
  const [programData, setProgramData] = useState([]);
  useEffect(() => {
    Papa.parse('https://docs.google.com/spreadsheets/d/e/2PACX-1vRrEWL_AJhasaofvNaQkQXjcn9fgTc0ZjdC1WBkvVmxjBFIMzclbQG2P5Rvqf15se2tDrPNgNvx4d0L/pub?gid=1438591293&single=true&output=csv', {
      download: true,
      header: true,
      complete: function(results) {
        var googleData = results.data
        console.log(googleData);
        setProgramData(googleData);
      }
    })
  }, [])

  return (
    <div className="program">
      <div className="programColumn">
        <p>     СБ, 24 АПР</p>
        {programData.map((item, i) => {
          if (item.time_sat) {
            return (
              <p>
                {item.time_sat}<br/>
                {item.event_name}<br/>
                место: {item.loc_name}<br/>
                {item.free ? 'бесплатно' : ''}
                {item.price ? item.price : ''}<br/>
                {item.registration_link ? <a href={item.registration_link} target='_blank'>регистрация<br/></a> : ''}
                {item.event_link ? <a href={item.event_link} target='_blank'>событие<br/></a> : ''}
              </p>
            )
          }
        })}
      </div>
      <div className="programColumn">
        <p>     ВС, 25 АПР</p>
        {programData.map((item, i) => {
          if (item.time_sun) {
            return (
              <p>
                {item.time_sun}<br/>
                {item.event_name}<br/>
                Место: {item.loc_name}<br/>
                {item.free ? 'бесплатно' : ''}
                {item.price ? item.price : ''}<br/>
                {item.registration_link ? <a href={item.registration_link} target='_blank'>регистрация<br/></a> : ''}
                {item.event_link ? <a href={item.event_link} target='_blank'>событие<br/></a> : ''}
              </p>
            )
          }
        })}
      </div>
    </div>
  )
}
