import React, { useState, useEffect, useRef } from "react";
import Tabletop from 'tabletop';
import Papa from "papaparse";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './App.scss';

import About from './components/About.jsx';
import Lineup from './components/Lineup.jsx';
import Artist from './components/Artist.jsx';
import Artists from './components/Artists.jsx';
import Head from './components/Head.jsx';
import Tag from './components/Tag.jsx';
import Program from './components/Program.jsx';

import graphicsImage1 from './images/graphics1.svg';
import graphicsImage3 from './images/graphics3.svg';
import graphicsImage4 from './images/graphics4.svg';

function App() {
  const [pathname, setPathname] = useState(window.location.pathname);

  const [ogData, setOgData] = useState([]);
  const [data, setData] = useState([]);

  const [artTypeFilters, setArtTypeFilters] = useState([]);
  const [formatFilters, setFormatFilters] = useState([]);
  const [locationFilters, setLocationFilters] = useState([]);
  const [artTechniqueFilters, setArtTechniqueFilters] = useState([]);

  const [appliedFormatFilter, setAppliedFormatFilter] = useState('все');
  const [appliedPlatformFilter, setAppliedPlatformFilter] = useState('все');

  const graphicsRef = useRef(null);

  useEffect(() => {
    setPathname(window.location.pathname);
  })

  useEffect(() => {
    //OLD 1 https://docs.google.com/spreadsheets/d/e/2PACX-1vRrEWL_AJhasaofvNaQkQXjcn9fgTc0ZjdC1WBkvVmxjBFIMzclbQG2P5Rvqf15se2tDrPNgNvx4d0L/pub?gid=131848015&single=true&output=csv
    //NEW 1 https://docs.google.com/spreadsheets/d/e/2PACX-1vQbvEfzeoWoftOrqZkuBhzvN2ZgR9lOTv94lLfXXvcxatS-glQVvPwbEMpxDBFPLkNu97azgP_yh10r/pub?output=csv

    Papa.parse('https://docs.google.com/spreadsheets/d/e/2PACX-1vThQqBqkKdcagbJXLiM9TKtxmLVIhmfHU6WjcJCHuGZmqTIbE6S-q9rmwSpX7WhmfaYRrG-3Z6qMFs7/pub?output=csv', {
      download: true,
      header: true,
      complete: function(results) {
        var googleData = results.data
        setOgData(googleData);
        setData(googleData);
        setUniqueFilters(googleData);
        console.log(googleData);
      }
    })

    checkScroll()
  }, [])

  function checkScroll() {
    let lastScroll = 0;
    setInterval(function () {
      lastScroll += 1

      if (lastScroll > 10) {
        graphicsRef.current.style.display = 'block';
      }
    }, 1000);

    window.addEventListener('scroll', function() {
      randImage();
      lastScroll = 0;
      graphicsRef.current.style.display = 'none';
    })

    window.addEventListener('click', function() {
      randImage();
      lastScroll = 0;
      graphicsRef.current.style.display = 'none';
    })
  }

  function setUniqueFilters(googleData) {
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    let art_type_arr = [];
    googleData.map(item => {
      let art_type_arr_single = item.art_type.split(', ');
      art_type_arr.push.apply(art_type_arr, art_type_arr_single)
    })
    let art_type_arr_unique = art_type_arr.filter(onlyUnique);
    let final_art_type_arr = countNumberOfOccurancies(art_type_arr_unique, googleData, 'art_type');
    setArtTypeFilters(final_art_type_arr);

    let format_arr = [];
    googleData.map(item => {
      let format_arr_single = item.format.split(', ');
      format_arr.push.apply(format_arr, format_arr_single)
    })
    let format_arr_unique = format_arr.filter(onlyUnique);
    let final_format_arr = countNumberOfOccurancies(format_arr_unique, googleData, 'format');
    setFormatFilters(final_format_arr);

    let location_arr = [];
    googleData.map(item => {
      let location_arr_single = item.location_type.split(', ');
      location_arr.push.apply(location_arr, location_arr_single)
    })
    let location_arr_unique = location_arr.filter(onlyUnique);
    let final_location_arr_unique = countNumberOfOccurancies(location_arr_unique, googleData, 'location_type');
    setLocationFilters(final_location_arr_unique);

    let art_technique_arr = [];
    googleData.map(item => {
      let art_technique_arr_single = item.art_technique.split(', ');
      art_technique_arr.push.apply(art_technique_arr, art_technique_arr_single)
    })
    let art_technique_arr_unique = art_technique_arr.filter(onlyUnique);
    let final_art_technique_arr_unique = countNumberOfOccurancies(art_technique_arr_unique, googleData, 'art_technique');
    setArtTechniqueFilters(final_art_technique_arr_unique);
  }

  function countNumberOfOccurancies(arr_unique, googleData, colname) {
    let newArr = arr_unique;
    arr_unique.map((filterName, i) => {
      let num = 0;
      googleData.map(item => {
        if (item[colname].split(', ').includes(filterName)) {
          num += 1;
        }
      })
      newArr[i] = `${filterName}&nbsp;(${num})`
    })
    return newArr;
  }

  let imagesArr = [graphicsImage1, graphicsImage3, graphicsImage4]
  let [graphicUrl, setGraphicUrl] = useState('');
  let [graphicName, setGraphicName] = useState('');
  function getRandNum() {
    let min = Math.ceil(0);
    let max = Math.floor(imagesArr.length - 1);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  useEffect(() => {
    randImage();
  }, [])

  function randImage() {
    let num = getRandNum();
    let img = imagesArr[num];
    setGraphicUrl(img);
    setGraphicName(`graphic${num}`)
  }

  return (
    <Router>
        <Switch>
          <Route path="/about">
            <img src={graphicUrl} className={`pageGraphics ${graphicName}`} ref={graphicsRef}/>
            <Head
              filters={false}
              ogData={ogData}
              artTypeFilters={artTypeFilters}
              formatFilters={formatFilters}
              locationFilters={locationFilters}
              artTechniqueFilters={artTechniqueFilters}
            />
            <About/>
          </Route>

          <Route path="/artists/:name">
            <img src={graphicUrl} className={`pageGraphics ${graphicName}`} ref={graphicsRef}/>
            <Head
              filters={true}
              ogData={ogData}
              artTypeFilters={artTypeFilters}
              formatFilters={formatFilters}
              locationFilters={locationFilters}
              artTechniqueFilters={artTechniqueFilters}
            />
            <Artist
              ogData={ogData}
              artTypeFilters={artTypeFilters}
              formatFilters={formatFilters}
              locationFilters={locationFilters}
              artTechniqueFilters={artTechniqueFilters}
            />
            <Artists ogData={ogData} data={data}/>
          </Route>

          <Route path="/tags/:tag">
            <img src={graphicUrl} className={`pageGraphics ${graphicName}`} ref={graphicsRef}/>
            <Head
              filters={true}
              ogData={ogData}
              artTypeFilters={artTypeFilters}
              formatFilters={formatFilters}
              locationFilters={locationFilters}
              artTechniqueFilters={artTechniqueFilters}
            />
            <Tag
              ogData={ogData}
              artTypeFilters={artTypeFilters}
              formatFilters={formatFilters}
              locationFilters={locationFilters}
              artTechniqueFilters={artTechniqueFilters}
            />
          </Route>

          <Route path="/program">
            <img src={graphicUrl} className={`pageGraphics ${graphicName}`} ref={graphicsRef}/>
            <Head
              filters={false}
              ogData={ogData}
              artTypeFilters={artTypeFilters}
              formatFilters={formatFilters}
              locationFilters={locationFilters}
              artTechniqueFilters={artTechniqueFilters}
            />
            <Program/>
          </Route>

          <Route path="/">
            <img src={graphicUrl} className={`pageGraphics ${graphicName}`} ref={graphicsRef}/>
            <Head
              filters={true}
              ogData={ogData}
              artTypeFilters={artTypeFilters}
              formatFilters={formatFilters}
              locationFilters={locationFilters}
              artTechniqueFilters={artTechniqueFilters}
            />
            <Artists data={data}/>
          </Route>
        </Switch>
    </Router>
  );
}

export default App;

// сб и вс две колонки / регистрация / описание / раскрывашка
