import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import Artists from './Artists.jsx';

export default function Tag(props) {
  let { tag } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tag])


  const[filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    let tagName = tag.split('(')[0].slice(0, -1);
    let arr = []

    props.ogData.map(item => {
      item.art_type.split(', ').map(type => {
        if (type.replace('/', '') == tagName) {
          arr.push(item);
        }
      })

      item.format.split(', ').map(type => {
        if (type.replace('/', '') == tagName) {
          arr.push(item);
        }
      })

      item.location_type.split(', ').map(type => {
        if (type.replace('/', '') == tagName) {
          arr.push(item);
        }
      })

      item.art_technique.split(', ').map(type => {
        if (type.replace('/', '') == tagName) {
          arr.push(item);
        }
      })
    })

    setFilteredData(arr);
  }, [tag])
  useEffect(() => {
    let tagName = tag.split('(')[0].slice(0, -1);
    let arr = []

    props.ogData.map(item => {
      item.art_type.split(', ').map(type => {
        if (type.replace('/', '') == tagName) {
          arr.push(item);
        }
      })

      item.format.split(', ').map(type => {
        if (type.replace('/', '') == tagName) {
          arr.push(item);
        }
      })

      item.location_type.split(', ').map(type => {
        if (type.replace('/', '') == tagName) {
          arr.push(item);
        }
      })

      item.art_technique.split(', ').map(type => {
        if (type.replace('/', '') == tagName) {
          arr.push(item);
        }
      })
    })

    setFilteredData(arr);
  }, [props.ogData])


  return (
    <>
      <Artists
        data={filteredData}
        artTypeFilters={props.artTypeFilters}
        formatFilters={props.formatFilters}
        locationFilters={props.locationFilters}
        artTechniqueFilters={props.artTechniqueFilters}
      />
    </>
  )
}
