import React, { useState, useEffect } from "react";
import Tabletop from 'tabletop';
import Papa from "papaparse";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";
import Masonry from 'react-masonry-css'

export default function Artist(props) {
  let { name } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [name])

  const [ogData, setOgData] = useState(props.ogData);
  useEffect(() => {
    setOgData(props.ogData)
  }, [props.ogData])

  const [tags, setTags] = useState([]);
  useEffect(() => {
    props.ogData.map(item => {
      if (item.name == name) {
        findTags(item)
      }
    })
  }, [name])

  useEffect(() => {
    props.ogData.map(item => {
      if (item.name == name) {
        findTags(item)
      }
    })
  }, [props.ogData])

  useEffect(() => {
    props.ogData.map(item => {
      if (item.name == name) {
        findTags(item)
      }
    })
  }, [])

  function findTags(artist) {
    let tagsArr = []
    // [мастерская, квартира]
    let format_arr = artist.format.split(', ');
    let location_type_arr = artist.location_type.split(', ');
    let art_type_arr = artist.art_type.split(', ');
    let art_technique_arr = artist.art_technique.split(', ');

    props.formatFilters.map(filter => {
      let filname = filter.split('&nbsp;(')[0]
      if (format_arr.includes(filname)) {
        if (filname != '') {
          tagsArr.push(filter)
        }
      }
    })

    props.locationFilters.map(filter => {
      let filname = filter.split('&nbsp;(')[0]
      if (location_type_arr.includes(filname)) {
        if (filname != '') {
          tagsArr.push(filter)
        }
      }
    })

    props.artTypeFilters.map(filter => {
      let filname = filter.split('&nbsp;(')[0]
      if (art_type_arr.includes(filname)) {
        if (filname != '') {
          tagsArr.push(filter)
        }
      }
    })

    props.artTechniqueFilters.map(filter => {
      let filname = filter.split('&nbsp;(')[0]
      if (art_technique_arr.includes(filname)) {
        if (filname != '') {
          tagsArr.push(filter)
        }
      }
    })

    setTags([]);
    setTags(tagsArr);
  }

  function setImageWidth() {
    let min = Math.ceil(50);
    let max = Math.floor(100);
    let rand = Math.floor(Math.random() * (max - min + 1)) + min;
    let style = {
      width: `${rand}%`
    };
    return style;
  }

  const breakpointColumnsObj = {
    default: 2,
    768: 1,
  };

  return (
    <>
      {ogData.map(item => {
        if (item.name.replace('/', '') == name) {
          let images = item.img.split(', ')
          return(
            <div className="artist">
              <p className="name">{item.num}. {item.name}</p>
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">
                {images.map((image,i) => {
                  return(
                    <img i={i} src={image}/>
                  )
                })}
              </Masonry>
              <p className="text">
                {item.description}
                <br/><br/>
                Время: сб {item.time_sat} / вс {item.time_sun}<br/>
                Адрес: {item.address}
                <br/><br/>
                Участники проекта:<span> </span>
                {item.members.split(', ').map((member, i) => {
                  let memberArr = member.split(' [')
                  return (
                    <span>
                      <a
                        key={i}
                        className="member"
                        href={memberArr[1] ? memberArr[1].replace(/]/i, '') : ''} target="_blank"
                      >
                        {`${memberArr[0]}`}
                      </a>
                      {i + 1 < item.members.split(', ').length ? ', ' : ''}
                    </span>
                  );
                })}
                <br/><br/>
                Теги:<span> </span>
                {tags.map((tag, i) => {
                  return(
                    <span>
                      <Link key={i} to={`/tags/${tag.replace(/(&nbsp;)/g, '\u00A0').replace('/', '')}`}>{tag.replace(/(&nbsp;)/g, '\u00A0').toLowerCase()}</Link>
                      {i + 1 < tags.length ? ', ' : ''}
                    </span>
                  )
                })}
              </p>
            </div>
          )
        }
      })}
    </>
  )
}
