import React, { useState, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

export default function Artists(props) {
  return (
    <div className="artistsGrid">
      {props.data.map((item, i) => {
        console.log(item);
        let images = item.img.split(', ')
        return(
            <div className="artitstCell">
              <p className="num"><Link to={`/artists/${item.name.replace('/', '')}`}>{item.num}</Link></p>
              <div className="imgName">
                <Link to={`/artists/${item.name.replace('/', '')}`}><img src={images[0]}/></Link>
                <Link to={`/artists/${item.name.replace('/', '')}`}><p className="name">{item.name}</p></Link>
              </div>
            </div>
        )
      })}
    </div>
  )
}
